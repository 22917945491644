import React, { useState, useEffect } from 'react';
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import './earthly.css';
import {Row,Nav,Tab,Container,Col} from 'react-bootstrap';
import hero_img from '../images/earthly_banner.png';


import user_icon_login from '../images/user_login_icon.svg';
import cause_case_one from '../images/cause_case_one.svg';
import Axios from 'axios';
import Qs from 'querystring';
import {API_URL,CONFIG_WITHOUT_TOKEN} from '../appconstant/index';
import Slider from "react-slick";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import earthly_cap from "../images/tour/cap.svg";

const percentage = 10;


const Profile = () => {

  const settings = {
       dots: false,
       infinite: false,
       speed: 500,
       slidesToShow: 4,
       slidesToScroll: 1,
       className:"profile",
       responsive: [
         {
           breakpoint: 1024,
           settings: {
             slidesToShow: 3,
             slidesToScroll: 1,

           }
         },
         {
           breakpoint: 600,
           settings: {
             slidesToShow: 2,
             slidesToScroll: 1,
           }
         },
         {
           breakpoint: 480,
           settings: {
             slidesToShow: 1,
             slidesToScroll: 1,
           }
         }
       ]
     };
     const [userdata,setUserdata] = useState("")
     const copyText = (e) => {
         var copyText = document.getElementById("copyclipboard");
         copyText.select();
         copyText.setSelectionRange(0, 99999)
         document.execCommand("copy");
     }
     const getuserdata = (token) =>{
         let CONFIG_WITH_TOKEN = {
           headers: {
             'Content-Type': 'application/x-www-form-urlencoded',
             'Authorization': 'Bearer ' + token,
           }
         }
         Axios.get(API_URL + '/apis/user/data',CONFIG_WITH_TOKEN)
                   .then((response) => {
                         if(response.data.error == 0){
                            setUserdata(response.data.user);
                            localStorage.setItem("earthly_login_user",JSON.stringify(response.data.user));
                         }
                   })
                   .catch((error) => {

                   });
     }
     const totalreferral = (ref,thereref) => {
         let userref = ref ? ref :"0";
         let thererefer = thereref ? thereref:"0";
         let total = parseInt(userref) + parseInt(thererefer);
         return total == 0 ? "1":total;
     }
     useEffect(() => {
         let token = localStorage.getItem("earthly_web_token");
         if(token !== null && token !== undefined){
                getuserdata(token);
         }


     },[]);
  return (

    <Layout style={{width:"auto"}}>
    <SEO title="Profile" image={hero_img} />
      <div>
        <div className="row profile_header_bg">
        </div>
        <div className="profile_img_section">
              <img src={userdata.photo ?userdata.photo: user_icon_login} className="profileimg" />
              <p>{userdata.first_name ? userdata.first_name:"" + " " + userdata.last_name ? userdata.last_name:""} </p>
        </div>
        <div>
          <div className="profile_card_details mb-4">
              <Tab.Container id="center-tabs-example" defaultActiveKey="public_profile">
                <Row style={{margin:"0"}}>
                <Col sm={12} style={{padding:"8px 0px 0px 8px",borderBottom:"1px solid #cccccc"}}>
                  <Nav variant="pills" style={{textAlign:"center",justifyContent:"center"}}>
                    <Nav.Item>
                      <Nav.Link eventKey="public_profile">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="setting">Settings</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12} className="mt-3">
                  <Tab.Content>
                    <Tab.Pane eventKey="public_profile">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        {typeof userdata.products === "object" &&
                            userdata.products.length !== 0 &&
                            <div className="row">
                                <div className="col-md-12 icons">
                                  <div className="col-md-4">
                                    <p className="mb-2">Influencer</p>
                                     <CircularProgressbar
                                        value={userdata.influence_rating_percetange?userdata.influence_rating_percetange:"1"}
                                        text={`${userdata.influence_rating?userdata.influence_rating:"1"}x`}
                                        styles={buildStyles({
                                          textColor: "#000000",
                                          pathColor: "#FBB313",
                                          trailColor: "#254AB9",
                                          strokeLinecap: 'butt',
                                          width:"160px",
                                        })}
                                      />
                                     <label className="rightside"></label>
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <p className="mb-1 "><label className="rating_description_text_popup"></label>{userdata.user_score ? userdata.user_score:"1"}</p>
                                            <p className="mb-1" style={{color:"#666666",fontSize:"15px"}}>purchases by you</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="mb-1"><label className="rating_description_text_popup_blue"></label>{userdata.influence_score ? userdata.influence_score:"1"}</p>
                                            <p className="mb-1" style={{color:"#666666",fontSize:"15px"}}>purchases influenced by you</p>
                                        </div>
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <div>
                                      <p className="public_profile_tab_sub_title">Causes Showcase</p>
                                    </div>
                                    <div className="icons">
                                    {typeof userdata.icons === "object" &&
                                            userdata.icons.length > 0  &&
                                            userdata.icons.map((value,index) => {
                                                let img_url = value.icon;
                                                let img_color = value.color;
                                                let fix_url =  "https://earthlydev.s3-us-west-2.amazonaws.com/icons";
                                                let icon_img_url = img_url.replace("https://earthlydev.s3-us-west-2.amazonaws.com/icons", fix_url + "/" + img_color.toLowerCase()).replace('.png', ".svg");
                                                let icon_text = value.text;
                                                return(
                                                        <div className="cause_list" key={index+1}>
                                                          <img src={icon_img_url} alt="search" style={{height:"48px"}} />
                                                          <p className="cause_case_title">{icon_text}</p>
                                                        </div>
                                                )
                                            })


                                    }
                                    </div>
                                    <div>
                                      <div className="mt-2">
                                        <p className="public_profile_tab_sub_title" style={{marginBottom:"5"}}>Virtual product shelf</p>
                                      </div>
                                        <Slider {...settings}>
                                        {typeof userdata.products === "object" &&
                                                userdata.products.length > 0  &&
                                                userdata.products.map((value,index) => {
                                                    return(
                                                        <div key={index + 1}>
                                                            <div className="brand_slider_name">
                                                                <p className="profile_brand_title">{value.Brand}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        }
                        {typeof userdata.products === "object" &&
                            userdata.products.length === 0 &&

                                <div style={{textAlign:"center",marginTop:"15px"}}>
                                    <img src={earthly_cap} alt="Empty image" />
                                    <p className="profilepageshoptext">Good job on installing Earthly's shopping extension.</p>
                                    <p className="profilepageshoptext"> Shop a little and come back here to see your impact</p>
                                    <a href="https://www.amazon.com"><button className="gotoshop">Shop on Amazon.com</button></a>
                                </div>
                        }
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="setting">
                        <p style={{textAlign:"center"}}>{"Coming Soon"}</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
                </Row>
                </Tab.Container>

          </div>
          <div className="profile_share_details">
            <div className="row" style={{padding:"10px"}}>
              <div className="col-md-6">
                  <div className="profile_forest_tree">
                      <div className="row">
                        <div className="col-md-12 col-lg-5">
                            <div>
                            <table>
                              <tr>
                                <td style={{padding:0,borderBottom:"0"}}></td>
                                <td style={{padding:0,textAlign:"center",borderBottom:"0"}}><img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/forest.svg" style={{marginBottom:"20px"}}/></td>
                                <td style={{padding:0,borderBottom:"0"}}><label className="draw_line_first_tree"></label></td>
                              </tr>
                              <tr>
                                <td style={{padding:0,textAlign:"right",borderBottom:"0"}}><img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/forest.svg" style={{marginBottom:"20px",height:"40px"}}/></td>
                                <td style={{padding:0,borderBottom:"0"}}></td>
                                <td style={{padding:0,textAlign:"left",borderBottom:"0"}}><img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/forest.svg" style={{marginBottom:"20px",height:"40px"}}/><label className="draw_line_second_tree"></label></td>
                              </tr>
                              <tr>
                                <td style={{padding:0,textAlign:"left",borderBottom:"0"}}><img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/forest.svg" style={{marginBottom:"0",height:"30px"}}/></td>
                                <td style={{padding:0,textAlign:"center",borderBottom:"0"}}><img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/forest.svg" style={{marginBottom:"0",height:"30px"}}/></td>
                                <td style={{padding:0,textAlign:"right",borderBottom:"0"}}><img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/forest.svg" style={{marginBottom:"0",height:"30px"}}/><label className="draw_line_third_tree"></label></td>
                              </tr>
                            </table>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6" className="forest_trees">
                            <div style={{paddingTop:"9%"}}>
                              <p>Your Forest has: <strong> {totalreferral(userdata.user_referrals,userdata.their_referrals)}trees!</strong></p>
                            </div>
                            <div style={{paddingTop:"6%"}}>
                              <p>Your referrals: <strong> {userdata.user_referrals ? userdata.user_referrals:"0"} </strong></p>
                            </div>
                            <div style={{paddingTop:"5%"}}>
                              <p>Their Referrals: <strong> {userdata.their_referrals ? userdata.their_referrals:"0"}  </strong></p>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
              <div className="col-md-6" style={{justifyContent: "center",margin: "auto"}}>
                <div className="invite_friend_header">
                  <p className="invite_friend_text_profile">Refer a friend, we'll <a href="https://onetreeplanted.org/collections/asia/products/indonesia">plant two trees</a></p>
                </div>
                <div className="input-group mb-3" style={{width:"75%"}}>

                  <input type="text" className="form-control" id="copyclipboard" value={"https://joinearthly.com/invite/" + `${userdata._id}`} aria-describedby="basic-addon2"/>
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary copy_btn" onClick={copyText} data-clipboard-action="copy" data-clipboard-target="#copyclipboard" type="button">Copy</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Profile;
